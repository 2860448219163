/**
 * CommonList Component
 * 
 * This component provides a reusable list view with filtering, pagination, and settings management.
 * It's designed to work with various item types (e.g., markets, matches, series, sports) and
 * can be configured for different data sources and display options.
 * 
 * Features:
 * - Fetches and displays data from a specified API endpoint
 * - Supports both GET and POST request types for data fetching
 * - Implements pagination with customizable page size
 * - Provides a settings modal for updating item-specific settings
 * - Includes a filter form for refined data searches (optional)
 * - Uses Material-UI components for consistent styling
 * 
 * Props:
 * - title: The title of the list view
 * - fetchUrl: API endpoint for fetching data
 * - updateSettingsUrl: API endpoint for updating item settings
 * - itemType: Type of items in the list (e.g., 'market', 'match')
 * - columns: Column definitions for the table
 * - defaultSettings: Default values for item settings
 * - searchRequestType: 'GET' or 'POST' for data fetching
 * - showFilter: Whether to display the filter form (default: true)
 */


import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Paper, Typography, styled, Toolbar } from '@mui/material';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import * as Yup from 'yup';
import { useApi } from '../../hooks/useApi';
import CommonTable from './CommonTable';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SearchCriteria } from './FilterForm';
import SettingsModal from './SettingsModal';
import FilterForm from './FilterForm';
import { getCommonListFormFields } from './commonformConfig';

interface RequestBody {
  items?: number;
  page: number;
  perPage?: number;
  [key: string]: any;
}

interface CommonListProps {
  title: string;
  fetchUrl: string;
  addUrl: string;
  updateSettingsUrl: string;
  itemType: string;
  columns: MRT_ColumnDef<Record<string, any>>[];
  defaultSettings: any;
  searchRequestType: 'GET' | 'POST';
  showFilter?: boolean;
}

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: theme.palette.primary.main,
}));

const allFields = [
  'name', 'marketId', 'matchId', 'seriesId', 'order_by', 'min_odds', 'max_odds',
  'bm_min_odds', 'bm_max_odds', 'match_bet_delay', 'bookmaker_bet_delay',
  'session_bet_delay', 'match_min_stack', 'match_max_stack', 'match_max_profit',
  'session_min_stack', 'session_max_stack', 'session_max_profit',
  'bookmaker_min_stack', 'bookmaker_max_stack', 'bookmaker_max_profit'
];

const validationSchema = Yup.object().shape(
  allFields.reduce((acc, field) => {
    acc[field] = Yup.string().required('Required');
    return acc;
  }, {} as Record<string, Yup.StringSchema>)
);

const formatDataForSelect = (cateogarisedData: any) => {
  return {
    sport: Object.entries(cateogarisedData.sport).map(([id, name]) => ({
      value: id,
      label: name as string
    })),
    series: Object.entries(cateogarisedData.series).map(([id, name]) => ({
      value: id,
      label: name as string
    })),
    match: Object.entries(cateogarisedData.match).map(([id, name]) => ({
      value: id,
      label: name as string
    }))
  };
};

const CommonList: React.FC<CommonListProps> = ({
  title,
  fetchUrl,
  updateSettingsUrl,
  itemType,
  columns,
  defaultSettings,
  searchRequestType,
  showFilter = true,
}) => {
  const [data, setData] = useState<any[]>([]);
  const initialDataRef = useRef<any[]>([]);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string>("");
  const [itemSettings, setItemSettings] = useState(defaultSettings);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [totalItems, setTotalItems] = useState(0);
  const { sendRequest } = useApi();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const fetchData = async (
    pageIndex: number,
    pageSize: number,
    body?:any,
    criteria?: SearchCriteria
  ) => {
    try {
      const userId = JSON.parse(localStorage.getItem('userDetails') || '{}')?.id;
      console.log("Page Index", pageIndex);
      let requestBody: RequestBody = {
        items: pageSize,
        page: pageIndex + 1,
        total_items:0,
        ...body,
        user_id: userId,
      };

      if (itemType === 'result-fancy' || itemType === 'result-market' || itemType === 'result-rollback') {
        const { items, ...restRequestBody } = requestBody;
        requestBody = { ...restRequestBody, perPage: pageSize };
      }

      const result = await sendRequest(
        fetchUrl,
        searchRequestType,
        requestBody
      );

      if (result.data) {
        setData(result.data?.data || result.data);
        if (initialDataRef.current.length === 0) {
          initialDataRef.current = result.data?.data || result.data;
        }
        setTotalItems(result?.data?.meta?.total || result.data?.length);
      }
    } catch (err) {
      toast.error("Failed to fetch data", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const cateogarisedData = useMemo(() => {
    const result: any = {
      series: {},
      match: {},
      sport: {},
    };

    Array.isArray(initialDataRef.current) && initialDataRef.current.forEach(item => {
      const { sport_id, match_id, series_id, series_name, name, sport_name } = item;
      if (!result.sport[sport_id]) {
        result.sport[sport_id] = sport_name;
      }
      if (!result.series[series_id]) {
        result.series[series_id] = series_name;
      }
      if (!result.match[match_id]) {
        result.match[match_id] = name;
      }
    });

    return result;
  }, [initialDataRef.current]);

  const formattedSelectData = useMemo(() => formatDataForSelect(cateogarisedData), [cateogarisedData]);

  console.log(cateogarisedData)

  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize, defaultSettings);
  }, [pagination.pageIndex, pagination.pageSize]);

  const submitSettings = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem('userDetails') || '{}')?.id;
      const result = await sendRequest(updateSettingsUrl, "POST", {
        ...itemSettings,
        [`${itemType}_id`]: selectedItemId,
      
      });

      if (result.message) {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 5000,
        });
      } else {
        toast.error(`Failed to update ${itemType} settings.`, {
          position: "top-center",
          autoClose: 5000,
        });
      }
    } catch (err) {
      toast.error(`An error occurred while updating ${itemType} settings.`, {
        position: "top-center",
        autoClose: 5000,
      });
    }
    setOpenSettingsModal(false);
    setItemSettings(defaultSettings);
  };

  const handleAddItem = () => { };

  const handlePaginationChange = (
    updaterOrValue:
      | MRT_PaginationState
      | ((prevState: MRT_PaginationState) => MRT_PaginationState)
  ) => {
    setPagination((prev) => {
      const newPagination =
        typeof updaterOrValue === "function" ? updaterOrValue(prev) : updaterOrValue;

      return {
        ...newPagination,
        pageIndex: isNaN(newPagination.pageIndex) ? 0 : newPagination.pageIndex,
        pageSize: isNaN(newPagination.pageSize) ? prev.pageSize : newPagination.pageSize,
      };
    });
  };

  const handleSubmitSettings = async (formData: any) => {
    try {
      const result = await sendRequest(updateSettingsUrl,'POST',{...formData,[`${itemType}_id`]: selectedItemId });

      if (result.message) {
        toast.success(result.message);
        fetchData(pagination.pageIndex, pagination.pageSize);
      } else {
        toast.error(`Failed to update ${itemType} settings.`, { position: 'top-center', autoClose: 5000 });
      }
    } catch (err) {
      toast.error(`An error occurred while updating ${itemType} settings.`, { position: 'top-center', autoClose: 5000 });
    }
    setIsSettingsModalOpen(false);
    setItemSettings(defaultSettings);
  };

  const handleSettingsClick = (itemId: string, index: number) => {
    setSelectedItemId(itemId);
    setSelectedIndex(index);
    setItemSettings(data[index]);
    setIsSettingsModalOpen(true);
  };

  const handleFilterClick = (criteria: any) => {
    console.log("Page Number", pagination.pageIndex);
    setPagination(prev => ({ pageIndex: 0, pageSize: prev.pageSize }));
    fetchData(pagination.pageIndex, pagination.pageSize, criteria);
  };

  return (
    <>
      <ToastContainer />
      <StyledToolbar>
        <Typography variant="h4">{title}</Typography>
      </StyledToolbar>
      <Paper elevation={0} sx={{ p: 3, m: 2 }}>
        {showFilter && (
          <FilterForm
            onSearch={handleFilterClick}
            fields={getCommonListFormFields(itemType, formattedSelectData)}
            itemType={itemType}
          />
        )}
        <CommonTable
          data={data}
          showFilter={showFilter}
          columns={columns}
          pagination={pagination}
          totalItems={totalItems}
          onPaginationChange={handlePaginationChange}
          onSettingsClick={(itemId: string) => {
            const index = data.findIndex(item => item.id === itemId);
            handleSettingsClick(itemId, index);
          }}
          itemType={itemType}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      </Paper>
      <SettingsModal
        open={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        onSubmit={handleSubmitSettings}
        initialValues={itemSettings}
        selectedIndex={selectedIndex}
      />
    </>
  );
};

export default CommonList;