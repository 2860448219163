import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import theme from '../utils/customTheme';

const ClockContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.6)', // Dark tint of black with 60% opacity
  borderRadius: '1em',
  padding: '15px',
  boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
  backdropFilter: 'blur(4px)',
  WebkitBackdropFilter: 'blur(4px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  maxWidth: '300px',
  color: 'rgba(255, 255, 255, 0.9)', // Light text color for contrast
}));

const ClockFace = styled('div')({
  width: '80px',
  height: '80px',
  borderRadius: '50%',
  backgroundColor: '#f0f0f0',
  position: 'relative',
  border: '6px solid white',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '8px',
    height: '8px',
    backgroundColor: '#333',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
});

const ClockHand = styled('div')<{ rotation: number; length: string; width: string; color: string }>(
  ({ rotation, length, width, color }) => ({
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transformOrigin: 'bottom center',
    transform: `rotate(${rotation}deg)`,
    backgroundColor: color,
    height: length,
    width: width,
    borderRadius: '4px',
    transition: 'all 0.05s',
    transitionTimingFunction: 'cubic-bezier(0.1, 2.7, 0.58, 1)',
  })
);

const InfoContainer = styled('div')({
  marginLeft: '15px',
  display: 'flex',
  flexDirection: 'column',
});

const TimeContainer = styled('div')({
  fontSize: '24px',
  fontWeight: 'bold',
  color: 'white',
});

const DateContainer = styled('div')({
  fontSize: '14px',
  color: 'white',
  marginTop: '5px',
});

const Clock: React.FC = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const seconds = time.getSeconds() * 6;
  const minutes = time.getMinutes() * 6 + seconds / 60;
  const hours = ((time.getHours() % 12) / 12) * 360 + minutes / 12;

  const formattedDate = time.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const formattedTime = time.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <ClockContainer>
      <ClockFace>
        <ClockHand rotation={hours} length="25px" width="4px" color="#333" />
        <ClockHand rotation={minutes} length="35px" width="3px" color="#555" />
        <ClockHand rotation={seconds} length="40px" width="2px" color="#d81b60" />
      </ClockFace>
      <InfoContainer>
        <TimeContainer>{formattedTime}</TimeContainer>
        <DateContainer>{formattedDate}</DateContainer>
      </InfoContainer>
    </ClockContainer>
  );
};

export default Clock;