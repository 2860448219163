import * as React from 'react';
import {
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { runnersData } from '../../mockData/runnersDataMock';
import { useWebSocketContext } from '../../hooks/useWebSocket';


// Type definitions
interface Runner {
  id: string;
  name: string;
  ex: {
    availableToBack: Array<{ price: number; size: number }>;
    availableToLay: Array<{ price: number; size: number }>;
  };
}

interface MatchOddsData {
  market_id: string;
  min_stack: number;
  max_stack: number;
  runner_json: Runner[];
}

interface UpdatedMarketDetails {
  markets?: Array<{
    market_id: string;
    runner_json: Array<{ pl?: number }>;
  }>;
}



// Extend the default theme
declare module '@mui/material/styles' {
  interface Palette {
    backButtonColor: string;
    layButtonColor: string;
  }
  interface PaletteOptions {
    backButtonColor?: string;
    layButtonColor?: string;
  }
}
interface StyledButtonProps {
  buttonVariant?: string;
  children: React.ReactNode;
  sx?: object;
}
// Styled components
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const StyledButton = styled(Button)<StyledButtonProps>(({ theme, buttonVariant }) => ({
  fontSize: theme.typography.pxToRem(14),
  alignItems: 'center',
  color: theme.palette.text.primary,
  textAlign: 'center',
  padding: '2px 2px 2px !important',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 0,
  width: '95%',
  background: buttonVariant === 'back'
    ? theme.palette.backButtonColor
    : theme.palette.layButtonColor,
  '&:hover': {
    background: buttonVariant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
  '&:active': {
    background: buttonVariant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
  '&:focus': {
    background: buttonVariant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
}));

const TeamTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginTop: theme.spacing(1.25),
  fontWeight: 700,
}));

const CardRoot = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[0],
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1.25),
}));

const PLTypography = styled(Typography)<{ isPositive: boolean }>(({ theme, isPositive }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
  color: isPositive ? 'green' : 'red',
  backgroundColor: isPositive ? '#e6ffe6' : '#ffe6e6',
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  marginLeft: 'auto',
}));
const StyledGridHeadContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: '12px 20px 12px 20px',
  marginBottom: '8px'
}))
const EventList: React.FC = () => {

  const { marketOdds, fancyValues } = useWebSocketContext();

  return (
    <>
      <StyledGridHeadContainer item lg={12} >
        <Typography>Match Odds</Typography>
      </StyledGridHeadContainer>
      <CardRoot>
        <Grid container>

          {marketOdds?.runners?.map((i: Runner, index: number) => (
            <React.Fragment key={i.id}>
              <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }}>
                <TeamTypography>{i.name}</TeamTypography>
                {/* {updatedRunnerJSON && updatedRunnerJSON[index] && updatedRunnerJSON[index].pl !== undefined && (
                <PLTypography isPositive={updatedRunnerJSON[index].pl >= 0}>
                  {updatedRunnerJSON[index].pl >= 0 ? '+' : '-'}
                  {Math.abs(updatedRunnerJSON[index].pl)}
                </PLTypography>
              )} */}
              </Grid>
              <Grid item xs={2.5} sx={{ marginBottom: '3.1px' }}>
                <StyledButton
                  buttonVariant="back"
                  sx={{ marginBottom: '3.1px', borderRadius: '8px 0px 0px 8px ', padding: '8px !important' }}
                >
                  <Typography fontSize={'12px'} fontWeight={600}>
                    {i.ex['availableToBack'][0].price}
                  </Typography>
                  <Typography fontSize={'9px'}>
                    {i.ex['availableToBack'][0].size}
                  </Typography>
                </StyledButton>
              </Grid>
              <Grid item xs={2.5}>
                <StyledButton
                  buttonVariant="lay"

                  sx={{ marginBottom: '3.1px', borderRadius: '0px 8px 8px 0px ', padding: '8px !important' }}
                >
                  <Typography fontSize={'12px'} fontWeight={600}>
                    {i.ex['availableToLay'][0].price}
                  </Typography>
                  <Typography fontSize={'9px'}>
                    {i.ex['availableToLay'][0].size}
                  </Typography>
                </StyledButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </CardRoot>

      <StyledGridHeadContainer item lg={12} >
        <Typography>Fancy</Typography>
      </StyledGridHeadContainer>
      <CardRoot>
        <Grid container>

          {fancyValues?.map((i: any, index: number) => (
            <React.Fragment key={i.id}>
              <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }}>
                <TeamTypography>{i.RunnerName}</TeamTypography>
                {/* {updatedRunnerJSON && updatedRunnerJSON[index] && updatedRunnerJSON[index].pl !== undefined && (
                <PLTypography isPositive={updatedRunnerJSON[index].pl >= 0}>
                  {updatedRunnerJSON[index].pl >= 0 ? '+' : '-'}
                  {Math.abs(updatedRunnerJSON[index].pl)}
                </PLTypography>
              )} */}
              </Grid>
              <Grid item xs={2.5} sx={{ marginBottom: '3.1px' }}>
                <StyledButton
                  buttonVariant="back"
                  sx={{ marginBottom: '3.1px', borderRadius: '8px 0px 0px 8px ', padding: '8px !important' }}
                >
                  <Typography fontSize={'12px'} fontWeight={600}>
                    {i.BackPrice1}
                  </Typography>
                  <Typography fontSize={'9px'}>
                    {i.BackSize1}
                  </Typography>
                </StyledButton>
              </Grid>
              <Grid item xs={2.5}>
                <StyledButton
                  buttonVariant="lay"

                  sx={{ marginBottom: '3.1px', borderRadius: '0px 8px 8px 0px ', padding: '8px !important' }}
                >
                  <Typography fontSize={'12px'} fontWeight={600}>
                    {i.LayPrice1}
                  </Typography>
                  <Typography fontSize={'9px'}>
                    {i.LaySize1}
                  </Typography>
                </StyledButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </CardRoot>
    </>

  );
};

export default React.memo(EventList);