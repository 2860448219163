import { Typography } from '@mui/material';
import { GET_PROFIT_LOSS_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { MRT_ColumnDef } from 'material-react-table';

const ProfitAndLoss: React.FC = () => {

  const profitLossColumns = [
    { accessorKey: 'match_name', header: 'Match' },
    { accessorKey: 'market_name', header: 'Market' },
    { accessorKey: 'result', header: 'Result' },
    {
      accessorKey: 'created_at',
      header: 'Settled At',
      Cell: ({ row }: any) => formatDate(row.original.created_at),
    },
    {
      accessorKey: 'stack',
      header: 'Stack',
    },
    {
      accessorKey: 'commission',
      header: 'Commission',
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
    },
  ];

  const defaultProfitLossSettings = {
    start_date: '',
    end_date: '',
  };

  return (
    <CommonList
      title="Profit Loss"
      fetchUrl={GET_PROFIT_LOSS_URL}
      itemType="report-profitLoss"
      columns={profitLossColumns as MRT_ColumnDef<Record<string, any>, unknown>[]}
      defaultSettings={defaultProfitLossSettings}
      searchRequestType={'POST'} 
      addUrl={''} 
      updateSettingsUrl={''}    />
  );
};

export default ProfitAndLoss;