import { useState } from 'react';
import { json, useNavigate } from 'react-router-dom';
//always return data and status as per standard 

export const useApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
   const navigate = useNavigate();

  const sendRequest = async (url: string, method: string, body: any) => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('authToken')
   
      let requestObj: RequestInit = {
        method,
        headers: {
          "accept": "application/json, text/plain, */*",
          "authorization": `${token}`,
          "content-type": "application/json",
        },
        mode: "cors",
        credentials: "include"
      };

      if (body) {
        requestObj.body = JSON.stringify(body);
      }

      let finalUrl = url;

      if (method.toLowerCase() === 'get') {
        delete requestObj.body;
        if (body) {
          const params = new URLSearchParams();
          Object.entries(body).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
              params.append(key, value.toString());
            }
          });
          const queryString = params.toString();
          if (queryString) {
            finalUrl += (finalUrl.includes('?') ? '&' : '?') + queryString;
          }
        }
      }

      const response = await fetch(finalUrl, requestObj);
      const data = await response.json();
      console.log(response)
      setIsLoading(false);
      if (!response.ok) {
        if(response.status === 403){
          localStorage.removeItem('userDetails');
          localStorage.removeItem('token');
          navigate('/login');
        }else if(response.status===400 || response.status===500){
          return {...data,status:response.status}
        }else{
          throw new Error('Something went wrong');
        }
      }

      return {...data, status:response.status}
    } catch (err) {
      setIsLoading(false);
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      throw err;
    }
  };

  return { isLoading, error, sendRequest };
};