import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Button,
    Grid,
    MenuItem,
    Paper,
    TextField,
    Typography,
    styled
} from '@mui/material';
import { toast } from 'react-toastify';
import { useApi } from '../../hooks/useApi';
import { UPDATE_SITE_SETTING_URL } from '../../utils/apiUrl';
import theme from '../../utils/customTheme';

const HeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
    },
    padding: theme.spacing(1.5, 3),
    fontWeight: 'bold',
    boxShadow: theme.shadows[3],
}));

interface SiteSettingsFormValues {
    lockFancyBet: string;
    lockMatchBet: string;
    unmatchedBet: string;
    lockRegister: string;
    defaultAdmin: string;
    betAllowTime: string;
    dwSupport: string;
    whatsAppChat: string;
    mobile3: string;
    notificationMobile1: string;
    notificationMobile2: string;
    withdrawalRequestLimit: string;
    depositRequestLimit: string;
    siteMessage: string;
    termsConditions: string;
}

const initialValues: SiteSettingsFormValues = {
    lockFancyBet: 'Inactive',
    lockMatchBet: 'Inactive',
    unmatchedBet: 'Inactive',
    lockRegister: 'unlock',
    defaultAdmin: '',
    betAllowTime: '1800',
    dwSupport: 'https://wa.me/919900',
    whatsAppChat: 'https://wa.me/9174293',
    mobile3: '0',
    notificationMobile1: '8296271111',
    notificationMobile2: '72927',
    withdrawalRequestLimit: '10',
    depositRequestLimit: '10',
    siteMessage: "Welcome To India's Best Online Games",
    termsConditions: '',
};

const validationSchema = Yup.object({
    lockFancyBet: Yup.string().required('Required'),
    lockMatchBet: Yup.string().required('Required'),
    unmatchedBet: Yup.string().required('Required'),
    lockRegister: Yup.string().required('Required'),
    defaultAdmin: Yup.string().required('Required'),
    betAllowTime: Yup.number().required('Required').positive('Must be positive'),
    dwSupport: Yup.string().url('Must be a valid URL').required('Required'),
    whatsAppChat: Yup.string().url('Must be a valid URL').required('Required'),
    mobile3: Yup.string().required('Required'),
    notificationMobile1: Yup.string().matches(/^\d+$/, 'Must be only digits').required('Required'),
    notificationMobile2: Yup.string().matches(/^\d+$/, 'Must be only digits').required('Required'),
    withdrawalRequestLimit: Yup.number().required('Required').positive('Must be positive'),
    depositRequestLimit: Yup.number().required('Required').positive('Must be positive'),
    siteMessage: Yup.string().required('Required'),
    termsConditions: Yup.string().required('Required'),
});

const SiteSettings: React.FC = () => {
    const { sendRequest, isLoading, error } = useApi();

    const handleSubmit = async (values: SiteSettingsFormValues) => {
        const payload = {
            id: 1,
            site_message: values.siteMessage,
            is_unmatched_bet: values.unmatchedBet === 'Active' ? '1' : '0',
            odds_limit: 3,
            match_min_stack: 15,
            match_max_stack: 250,
            session_min_stack: 15,
            session_max_stack: 250,
            bookmaker_min_stack: 10,
            bookmaker_max_stack: 250,
            lock_fancy_bet: values.lockFancyBet === 'Active' ? '1' : '0',
            lock_match_bet: values.lockMatchBet === 'Active' ? '1' : '0',
            lock_register: values.lockRegister === 'lock' ? '1' : '0',
            terms_conditions: values.termsConditions,
            default_admin: parseInt(values.defaultAdmin),
            bet_allow_time: parseInt(values.betAllowTime),
            mobile1: values.dwSupport,
            mobile2: values.whatsAppChat,
            mobile3: values.mobile3,
            notification_mobile1: values.notificationMobile1,
            notification_mobile2: values.notificationMobile2,
            withdrawal_req_limit: parseInt(values.withdrawalRequestLimit),
            deposit_req_limit: parseInt(values.depositRequestLimit),
        };
        try {
            const response = await sendRequest(
                UPDATE_SITE_SETTING_URL,
                'POST',
                payload
            );
            if (response.status === 200) {
                toast.success(response.message, {
                    position: 'top-center',
                    autoClose: 2000
                });
            } else {
                toast.error(response.message || 'Failed to update site settings', {
                    position: 'top-center',
                    autoClose: 5000
                });
            }
        } catch (err) {
            console.error('API error:', err);
            toast.error('Failed to update site settings');
        }
    };

    return (
        <Paper elevation={3} sx={{ p: 3, m: 2 }}>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, touched }) => (
                    <Form>
                        <HeaderContainer>
                            <Typography variant="h4">Site Settings</Typography>
                            <SubmitButton
                                type="submit"
                                disabled={isLoading}
                                variant="contained"
                                size="small"
                                sx={{
                                    background: theme.palette.buttonSecondaryColor,
                                    color: "white",
                                }}
                            >
                                Update
                            </SubmitButton>
                        </HeaderContainer>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    select
                                    name="lockFancyBet"
                                    label="Lock Fancy Bet"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.lockFancyBet && errors.lockFancyBet}
                                    helperText={touched.lockFancyBet && errors.lockFancyBet}
                                >
                                    <MenuItem value="Inactive">Inactive</MenuItem>
                                    <MenuItem value="Active">Active</MenuItem>
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    select
                                    name="lockMatchBet"
                                    label="Lock Match Bet"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.lockMatchBet && errors.lockMatchBet}
                                    helperText={touched.lockMatchBet && errors.lockMatchBet}
                                >
                                    <MenuItem value="Inactive">Inactive</MenuItem>
                                    <MenuItem value="Active">Active</MenuItem>
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    select
                                    name="unmatchedBet"
                                    label="Unmatched Bet"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.unmatchedBet && errors.unmatchedBet}
                                    helperText={touched.unmatchedBet && errors.unmatchedBet}
                                >
                                    <MenuItem value="Inactive">Inactive</MenuItem>
                                    <MenuItem value="Active">Active</MenuItem>
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    select
                                    name="lockRegister"
                                    label="Lock Register"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.lockRegister && errors.lockRegister}
                                    helperText={touched.lockRegister && errors.lockRegister}
                                >
                                    <MenuItem value="unlock">Unlock</MenuItem>
                                    <MenuItem value="lock">Lock</MenuItem>
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="defaultAdmin"
                                    label="Default Admin"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.defaultAdmin && errors.defaultAdmin}
                                    helperText={touched.defaultAdmin && errors.defaultAdmin}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="betAllowTime"
                                    label="Bet Allow Time (Sec)"
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    error={touched.betAllowTime && errors.betAllowTime}
                                    helperText={touched.betAllowTime && errors.betAllowTime}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="dwSupport"
                                    label="D/W Support"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.dwSupport && errors.dwSupport}
                                    helperText={touched.dwSupport && errors.dwSupport}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="whatsAppChat"
                                    label="WhatsApp Chat"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.whatsAppChat && errors.whatsAppChat}
                                    helperText={touched.whatsAppChat && errors.whatsAppChat}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="mobile3"
                                    label="Mobile 3"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.mobile3 && errors.mobile3}
                                    helperText={touched.mobile3 && errors.mobile3}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="notificationMobile1"
                                    label="Notification Mobile 1"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.notificationMobile1 && errors.notificationMobile1}
                                    helperText={touched.notificationMobile1 && errors.notificationMobile1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="notificationMobile2"
                                    label="Notification Mobile 2"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.notificationMobile2 && errors.notificationMobile2}
                                    helperText={touched.notificationMobile2 && errors.notificationMobile2}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="withdrawalRequestLimit"
                                    label="Withdrawal Request Limit"
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    error={touched.withdrawalRequestLimit && errors.withdrawalRequestLimit}
                                    helperText={touched.withdrawalRequestLimit && errors.withdrawalRequestLimit}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="depositRequestLimit"
                                    label="Deposit Request Limit"
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    error={touched.depositRequestLimit && errors.depositRequestLimit}
                                    helperText={touched.depositRequestLimit && errors.depositRequestLimit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    name="siteMessage"
                                    label="Site Message"
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    error={touched.siteMessage && errors.siteMessage}
                                    helperText={touched.siteMessage && errors.siteMessage}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    name="termsConditions"
                                    label="Terms Conditions"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.termsConditions && errors.termsConditions}
                                    helperText={touched.termsConditions && errors.termsConditions}
                                />
                            </Grid>
                        </Grid>

                    </Form>
                )}
            </Formik>
        </Paper>
    );
};

export default SiteSettings;