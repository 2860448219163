import { Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import EventList from './EventList'
import SideBarLeft from '../SideBarLeft/SideBarLeft'
import EventSideBar from './eventSideBar'
import { useWebSocketContext } from '../../hooks/useWebSocket'
import { useLocation } from 'react-router-dom'

function MatchOdds() {
  const { subscribeToMarket, unsubscribeFromMarket } = useWebSocketContext();
  const location = useLocation();
  const currentMarketId = location.pathname.split('/')[3];
  const currentMatchId = location.pathname.split('/')[4];

  useEffect(() => {
    if (currentMatchId) {
      subscribeToMarket([currentMarketId], currentMatchId, true) //subscribing to market
    }
  }, [currentMarketId, currentMatchId])

  return (
    <Grid container spacing={1} padding={'4px 8px 4px 8px'}>
      <Grid item lg={8} >
        <EventList />
      </Grid>
      <Grid item lg={4}>

        <EventSideBar />
      </Grid>
    </Grid>
  )
}

export default MatchOdds