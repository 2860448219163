import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Grid, LinearProgress, styled, useMediaQuery,useTheme } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'
import {Theme} from '@mui/material/styles'
import NavBar, { NavBarProps } from '../Navbar'
import SideBarLeft from '../SideBarLeft/SideBarLeft'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const PaddingGrid = styled(Grid)({
  // paddingTop: '64px'
})

const MemoizedNavBar = React.memo<NavBarProps>(NavBar)
const MemoizedSideBarLeft = React.memo(SideBarLeft)

function RootLayout() {
  const theme = useTheme<Theme>()
  console.log(theme)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
const [isSideBarOpen,setIsSideBarOpen]=useState(false)
const navigate = useNavigate();
  const toggleSidebar = useCallback(()=>{
    setIsSideBarOpen(prev=>!prev)
  }, [isSideBarOpen,setIsSideBarOpen])
  const token = localStorage.getItem('authToken');
 

  const mobileLayout = useMemo(() => (
    <>
      <MemoizedNavBar handleOpenMenu={toggleSidebar} />
      <PaddingGrid container spacing={2} >
        <Grid item xs={12}  sx={{zIndex:1200}} >
        <MemoizedSideBarLeft toggled={isSideBarOpen} breakPoint='always' onBackdropClick={toggleSidebar} onClose={toggleSidebar}/>
          <Outlet />
        </Grid>
      </PaddingGrid>
    </>
  ), [toggleSidebar,isDesktop])

  const desktopLayout = useMemo(() => (
    <>
      
      <PaddingGrid container spacing={5}>
        <Grid item lg={2} sx={{background:'none',height:'100%'}}>
          <MemoizedSideBarLeft open={true} onClose={toggleSidebar} />
        </Grid>
        <Grid item lg={10} sx={{ marginTop: 0 }}>
        <MemoizedNavBar />
          <Outlet />
        </Grid>
      </PaddingGrid>
    </>
  ), [isDesktop])

  console.log('rendering root layout')
   useEffect(()=>{
  if(!token){
    console.log('token not found')
    navigate('/login')
  }
  },[token,navigate])
 
  return (
    <>
    <ToastContainer/>
      {/* {isLoading && (
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      {hasError && (
        <div>Connection error. Please check your internet connection.</div>
      )} */}
      {!isDesktop ? mobileLayout : desktopLayout}
    </>
  )
}

export default React.memo(RootLayout)