export const ADD_ACCOUNT_CONFIG = {
    ACCOUNT_TYPES: [
      { value: '10', label: 'User' },
      { value: '1', label: 'Super Admin' },
      { value: '2', label: 'Admin' },
      { value: '3', label: 'Super Master' },
      { value: '4', label: 'Super Dealer' },
      { value: '5', label: 'Dealer' },
      { value: '6', label: 'Super Agent' },
      { value: '7', label: 'Agent' },
      { value: '8', label: 'Affiliate' },
    ],
    DEFAULT_VALUES: {
      uplineCommission: '0',
      downlineCommission: '0',
      uplinePartnership: '0',
      downlinePartnership: '0',
    },
    VALIDATION: {
      CLIENT_NAME_MIN_LENGTH: 3,
      PASSWORD_MIN_LENGTH: 8,
      PHONE_REGEX: /^\+?[1-9]\d{1,14}$/,
    },
  };