import { Typography } from '@mui/material';
import { LIST_SERIES_URL, ADD_SERIES_URL, UPDATE_SERIES_SETTING_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';

const SeriesListPage: React.FC = () => {
  const seriesColumns = [
    { accessorKey: 'sport_name', header: 'Sport' },
    { accessorKey: 'name', header: 'Series' },
    {
      accessorKey: 'created_at', header: 'Created At',
      Cell: ({ row }: any) => {
        // Convert date to a human-readable format    
        return (
          <Typography>{formatDate(row.original.created_at)}</Typography>
        );
      },
    },
    {
      accessorKey: 'updated_at', header: 'Updated At',
      Cell: ({ row }: any) => {
        return (
          <Typography>{formatDate(row.original.created_at)}</Typography>
        );
      },
    },
  ];

  const defaultSeriesSettings = {
    // Define default series settings here
    // Add any specific series settings fields
  };

  return (
    <CommonList
      title="Series List"
      fetchUrl={LIST_SERIES_URL}
      addUrl={ADD_SERIES_URL}
      updateSettingsUrl={UPDATE_SERIES_SETTING_URL}
      itemType="series"
      columns={seriesColumns}
      defaultSettings={defaultSeriesSettings} searchRequestType={'POST'} />
  );
};

export default SeriesListPage;