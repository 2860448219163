import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { navMenuList } from "../../constants/navMenuList";
import Clock from "../Clock";
import { useWebSocketContext } from "../../hooks/useWebSocket";

const Backdrop = styled('div')<{ open: boolean }>(({ open }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1,
  display: open ? 'block' : 'none',
}));

interface SideBarLeftProps {
  toggled?: boolean;
  onClose: () => void;  // Add this line
  [key: string]: any;
}

const SidebarContainer = styled('div')<{ toggled: boolean }>(({ theme, toggled }) => ({
  position: 'fixed',
  height: '100%',
  zIndex: 2,
  fontFamily: '"Lato", "Helvetica Neue", sans-serif',
  transition: 'width 0.3s ease',
  width: toggled ? "65vw" : 0,
  background: `linear-gradient(135deg, ${theme.palette.bgColor} 0%, ${theme.palette.bgColor}CC 100%)`,
  backdropFilter: 'blur(10px)',
  WebkitBackdropFilter: 'blur(10px)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  [theme.breakpoints.up('md')]: {
    width: toggled ? "65vw" : "17vw",
  },
}));

const SidebarInner = styled('div')<{ toggled: boolean }>(({ theme, toggled }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    display: toggled ? 'flex' : 'none',
  },
}));

const SidebarContent = styled('div')({
  flexGrow: 1,
  overflowY: 'auto',
  padding: '10px',
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    outline: '1px solid slategrey'
  }
});

const ClockContainer = styled('div')({
  padding: '10px',
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
});

const SidebarMenu = styled('nav')({
  color: 'white',
});

const MenuItem = styled('div')(({ theme }) => ({
  padding: '0.75rem 1rem',
  cursor: 'pointer',
  fontWeight: 400,
  fontSize: '1rem',
  '&:hover': {
    fontWeight: 500,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const SubMenuTitle = styled('div')({
  padding: '0.75rem 1rem',
  cursor: 'pointer',
});

const SubMenuContent = styled('div')({
  paddingLeft: '1rem',
});

function SideBarLeft({ toggled = false, onClose, ...restProps }: SideBarLeftProps): JSX.Element {
  const navigate = useNavigate();
  const [openSubMenus, setOpenSubMenus] = useState<string[]>([]);
  const {matches, unsubscribeFromMarket } = useWebSocketContext();

  const handleNavigation = (path: string) => {
    unsubscribeFromMarket(['null']);
    if (path !== "#") {
      navigate(path);
    }
  };

  const toggleSubMenu = (id: string) => {
    setOpenSubMenus(prev => 
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

  const groupedMenuArray = useMemo(() => {
    return _.chain(matches)
      .groupBy("sport_name")
      .map((data, key) => ({
        gameType: key,
        seriesNames: _.chain(data)
          .groupBy("series_name")
          .map((seriesData, seriesKey) => ({
            seriesName: seriesKey,
            allMatches: seriesData,
          }))
          .value(),
      }))
      .value();
  }, [matches]);

  return (
    <>
      <Backdrop open={toggled} onClick={onClose} />
      <SidebarContainer toggled={toggled} {...restProps}>
        
      <SidebarInner toggled={toggled}>
        <SidebarContent>
        <ClockContainer>
          <Clock />
        </ClockContainer>
          <SidebarMenu>
            {navMenuList.map((el) => {
              if (el.hasOwnProperty("subMenu")) {
                return (
                  <MenuItem key={el.id} className="submenu">
                    <SubMenuTitle onClick={() => toggleSubMenu(el.id.toString())}>{el.name}</SubMenuTitle>
                    {openSubMenus.includes(el.id.toString()) && (
                      <SubMenuContent>
                        {el.subMenu?.map((subEl) => (
                          <MenuItem key={subEl.id} onClick={() => handleNavigation(subEl.path)}>
                            {subEl.name}
                          </MenuItem>
                        ))}
                      </SubMenuContent>
                    )}
                  </MenuItem>
                );
              }
              return (
                <MenuItem key={el.id} onClick={() => handleNavigation(el.path)}>
                  {el.name}
                </MenuItem>
              );
            })}

            {groupedMenuArray.map((el) => (
              <MenuItem key={el.gameType} className="submenu">
                <SubMenuTitle onClick={() => toggleSubMenu(el.gameType)}>{el.gameType}</SubMenuTitle>
                {openSubMenus.includes(el.gameType) && (
                  <SubMenuContent>
                    {el.seriesNames.map((elSeries) => (
                      <MenuItem key={elSeries.seriesName} className="submenu">
                        <SubMenuTitle onClick={() => toggleSubMenu(elSeries.seriesName)}>{elSeries.seriesName}</SubMenuTitle>
                        {openSubMenus.includes(elSeries.seriesName) && (
                          <SubMenuContent>
                            {elSeries.allMatches.map((elMatch) => (
                              <MenuItem key={elMatch.match_id} onClick={ () => handleNavigation(`/sports/${elMatch.sport_name?.toLowerCase()}/${elMatch.market_id}/${elMatch.match_id}`)}>
                                {elMatch.match_name}
                              </MenuItem>
                            ))}
                          </SubMenuContent>
                        )}
                      </MenuItem>
                    ))}
                  </SubMenuContent>
                )}
              </MenuItem>
            ))}
          </SidebarMenu>
        </SidebarContent>
     
      </SidebarInner>
    </SidebarContainer>
    </>
  );
}

export default React.memo(SideBarLeft);