import { Typography } from '@mui/material';
import { ACCOUNT_STATEMENT_URL, BET_HISTORY_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { MRT_ColumnDef } from 'material-react-table';

const BetHistory: React.FC = () => {
  const BetHistoryColumns = [
    { accessorKey: 'user_name', header: 'User Name' },
    { accessorKey: 'sport_name', header: 'Sport' },
    { accessorKey: 'series_name', header: 'Series' },
    { accessorKey: 'match_name', header: 'Match' },
    { accessorKey: 'market_name', header: 'Market' },
    { accessorKey: 'selection_name', header: 'Selection' },
    {
      accessorKey: 'odds',
      header: 'Odds',
    },
    {
      accessorKey: 'stack',
      header: 'Stack',
    },
    {
      accessorKey: 'p_l',
      header: 'P/L',
    },
    {
      accessorKey: 'liability',
      header: 'Liability',
    },
    {
      accessorKey: 'is_back',
      header: 'Back/Lay',
      Cell: ({ cell }: { cell: { getValue: () => string } }) => cell.getValue() === '0' ? 'Lay' : 'Back',
    },
    {
      accessorKey: 'created_at',
      header: 'Date',
      Cell: ({ row }: any) => formatDate(row.original.created_at),
    },
  ];

  const defaultBetHistorySettings = {
    start_date: '',
    end_date: '',
    statement_type: '0',
    type: '',
    user_name: '',
    user_id: '',
    page: 1,
    items: 50,
  };

  return (
    <CommonList
          title="Bet History"
          fetchUrl={BET_HISTORY_URL}
          itemType="report-bet-history"
          columns={BetHistoryColumns as MRT_ColumnDef<Record<string, any>, unknown>[]}
          defaultSettings={defaultBetHistorySettings}
          searchRequestType="POST"
          addUrl=""
          updateSettingsUrl=""
        />
  );
};

export default BetHistory;