import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
  ReactNode
} from 'react'
import { io, Socket } from 'socket.io-client'
import { BALANCE_URL } from '../utils/apiUrl';
import { useNavigate } from 'react-router-dom';

interface WebSocketContextType {
  sendMessage: (eventName: string, data: any) => void;
  connect: () => void;
  subscribeToMarket: (marketId: string[], matchId: string, isEventScreen: boolean) => void;
  unsubscribeFromMarket: (marketId: string[]) => void;
  marketsIdsArray: any[];
  fancyValues: any[];
  marketOdds: Record<string, any>;
  matches: Record<string, any>;
  updatedMarketDetails: Record<string, any>;
}


const WebSocketContext = createContext<WebSocketContextType | null>(null)


interface WebSocketProviderProps {
  children: ReactNode;
}

interface UserDetails {
  token: string;
  [key: string]: any;
}

interface MarketValues {
  market_id: string;
  [key: string]: any;
}

interface FancyValues {
  id: string;
  [key: string]: any;
}

const WebSocketProviderComponent: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [marketsIdsArray, setMarketsIdsArray] = useState<MarketValues[]>([])
  const [fancyValues, setFancyArray] = useState<FancyValues[]>([])
  const [marketOdds, setMarketOdds] = useState<Record<string, any>>({})
  const [matches, setMatches] = useState<Record<string, any>>({})
  const [updatedMarketDetails, setUpdatedMarketDetails] = useState<Record<string, any>>({})
  const [socket, setSocket] = useState<Socket | null>(null)
  // const navigate = useNavigate()
  const fetchBalance = useCallback(async () => {
    try {
      const userDetails: UserDetails = JSON.parse(localStorage.getItem('userDetails') || '{}')
      const token = userDetails.token

      const response = await fetch(BALANCE_URL, {
        headers: {
          "accept": "application/json, text/plain, */*",
          "authorization": token,
          "content-type": "application/json"
        },
        body: JSON.stringify({ name: "kkk" }),
        method: "POST",
        mode: "cors",
        credentials: "include"
      });

      if (response.status === 401) {
        localStorage.removeItem('userDetails');
        localStorage.removeItem('token');
        if (window.location.pathname !== '/login') {
          window.location.href='/login'
        }
      }

      if (!response.ok) {
        throw new Error('Failed to fetch balance');
      }

      const data = await response.json();

      const updatedUserDetails = { ...userDetails, ...data?.data?.[0] };
      localStorage.setItem('userDetails', JSON.stringify(updatedUserDetails));
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  }, []);

  useEffect(() => {
    const balanceInterval = setInterval(fetchBalance, 4000);
    return () => {
      clearInterval(balanceInterval);
    };
  }, [fetchBalance]);

  const parseMarketIds = (marketValues: MarketValues) => {
    setMarketsIdsArray(prev => {
      const marketIdx = prev.findIndex(i => i.market_id === marketValues.market_id);
      if (marketIdx === -1) {
        return [...prev, marketValues]
      } else {
        return prev.map((item, index) =>
          index === marketIdx ? { ...item, ...marketValues } : item
        );
      }
    })
  }

  const parseFancyValues = (fancyValues: FancyValues) => {
    setFancyArray(prev => {
      const fancyIdx = prev.findIndex(i => i.id === fancyValues.id);

      if (fancyIdx === -1) {
        return [...prev, fancyValues]
      } else {
        return prev.map((item, index) =>
          index === fancyIdx ? { ...item, ...fancyValues } : item
        );
      }
    })
  }


  const connectWebSocket = useCallback(() => {
    const userDetails: UserDetails = JSON.parse(localStorage.getItem('userDetails') || '{}')
    const token = userDetails.token
console.log('connecting to websocket')
    if (token) {
      const url = window.ENV_CONFIG?.REACT_APP_WEBSOCKET_HOST || process.env.REACT_APP_WEBSOCKET_HOST;
      const newSocket = io(`${url}`, {
        path: '/api/socket',
        transports: ['websocket'],
        upgrade: false,
        query: { token },
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 10000,
        timeout: 2000000,
      })

      newSocket.on('connect', () => {
        newSocket.emit('event', {
          key: 'match-with-match_odds',
          action: 'all',
          param: {
            isActive: '1',
            sportId: '',
          },
          token,
        })
      })

      newSocket.on('disconnect', () => {
        console.log("******** disconnect")
      })

      newSocket.on('error', (error: Error) => {
        console.log("******** error", error)
      })

      newSocket.on('message', (message: { key: string; data: any }) => {
        const { key, data } = message
        if (key === 'fancy') {
          parseFancyValues(JSON.parse(data || '{}'))
        } else if (key === 'odds') {
          parseMarketIds(JSON.parse(data || '{}'))
          setMarketOdds(JSON.parse(data || '{}'))
        } else if (key === 'market') {
          setUpdatedMarketDetails(data)
        } else if (key === 'match') {
          setMatches(data)
        }
      })

      setSocket(newSocket)
    }
  }, [])

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
  }, [connectWebSocket]);

  const subscribeToMarket = useCallback(
    (marketId: string[], matchId: string, isEventScreen: boolean) => {
      if (socket) {
        const userDetails: UserDetails = JSON.parse(
          localStorage.getItem('userDetails') || '{}'
        )
        const token = userDetails.token

        if (isEventScreen) {
          socket.emit('event', {
            action: 'all',
            key: 'market',
            param: {
              matchId,
            },
            token,
          })

          socket.emit('event', {
            action: 'all',
            key: 'bet',
            param: {
              isActive: "1",
              matchId,
            },
            token,
          })

          socket.on('message', (message: { key: string; data: any }) => {
            if (message.key === 'market') {
              const selectedGameMarketIds = message.data?.markets?.map((i: any) => i.market_id);
              if (selectedGameMarketIds && selectedGameMarketIds.length > 0) {
                socket.emit('event', {
                  action: 'subscribe',
                  key: 'subscribe',
                  param: {
                    ids: selectedGameMarketIds,
                  },
                  token,
                })
              }
            }
          })
        } else {
          socket.emit('event', {
            action: 'subscribe',
            key: 'subscribe',
            param: {
              ids: [...marketId],
            },
            token,
          })
        }
      }
    },
    [socket],
  )

  const unsubscribeFromMarket = useCallback(
    (marketId: string[]) => {
      if (socket) {
        socket.disconnect()
        setMarketsIdsArray([])
        setFancyArray([])
        setMarketOdds({})
        setUpdatedMarketDetails({})
        connectWebSocket()
      }
    },
    [socket, connectWebSocket],
  )

  const sendMessage = useCallback(
    (eventName: string, data: any) => {
      if (socket) {
        socket.emit(eventName, data)
      }
    },
    [socket],
  )

  const value = useMemo(
    (): WebSocketContextType => ({
      sendMessage,
      connect: connectWebSocket,
      subscribeToMarket,
      unsubscribeFromMarket,
      marketsIdsArray,
      fancyValues,
      marketOdds,
      matches,
      updatedMarketDetails,
    }),
    [
      marketsIdsArray,
      sendMessage,
      updatedMarketDetails,
      connectWebSocket,
      subscribeToMarket,
      unsubscribeFromMarket,
      fancyValues,
      marketOdds,
      matches
    ],
  )

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
}

export const WebSocketProvider = React.memo(WebSocketProviderComponent)

export const useWebSocketContext = (): WebSocketContextType => {
  const context = useContext(WebSocketContext)
  if (context === null) {
    throw new Error(
      'useWebSocketContext must be used within a WebSocketProvider'
    )
  }
  return context
}