import React from "react";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import { Box, Button, Typography } from "@mui/material";

export interface BaseTableProps<T extends Record<string, any>> {
  title?: string;
  columns: MRT_ColumnDef<T>[];
  data: T[];
  enableTopToolbar?: boolean;
  enableBottomToolbar?: boolean;
  enableColumnFilters?: boolean;
  enableGlobalFilter?: boolean;
  enablePagination?: boolean;
  showExportButtons?: boolean;
  manualPagination?: boolean;
  onPaginationChange?: (
    updaterOrValue:
      | MRT_PaginationState
      | ((prevState: MRT_PaginationState) => MRT_PaginationState)
  ) => void;
  pageCount?: number;
  rowCount?: number;
  state?: {
    pagination: MRT_PaginationState;
  };
}

function BaseTable<T extends Record<string, any>>({
  title,
  columns,
  data,
  enableTopToolbar = false,
  enableBottomToolbar = false,
  enableColumnFilters = false,
  enableGlobalFilter = false,
  enablePagination = false,
  showExportButtons = false,
  manualPagination = false,
  onPaginationChange,
  pageCount=0,
  rowCount=0,
  state,
}: BaseTableProps<T>) {
  return (
    <Box>
      {showExportButtons && (
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="error" sx={{ mr: 1 }}>
            PDF
          </Button>
          <Button variant="contained" color="success">
            Excel
          </Button>
        </Box>
      )}

      <MaterialReactTable
        columns={columns}
        data={data}
        enableTopToolbar={enableTopToolbar}
        enableBottomToolbar={enableBottomToolbar}
        enableColumnFilters={enableColumnFilters}
        enableGlobalFilter={enableGlobalFilter}
        enablePagination={enablePagination}
        enableColumnResizing={false}
        enableColumnPinning={true}
        initialState={{ density: "compact" }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        muiTableBodyCellProps={{
          align: "center",
        }}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            },
            "& .Mui-TableHeadCell-Content-Labels": {
              display: "flex",
              justifyContent: "center", // Changed to center
              alignItems: "center",
              width: "100%",
              position: "relative", // Added to allow absolute positioning of actions
            },
            "& .Mui-TableHeadCell-Content-Actions": {
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              position: "absolute", // Changed to absolute
              right: 0, // Positioned at the right edge
            },
          },
        }}
        manualPagination={manualPagination}
        onPaginationChange={onPaginationChange}
        pageCount={pageCount}
        rowCount={rowCount}
        state={state}
      />
    </Box>
  );
}

export default BaseTable;
