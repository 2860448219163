import React from 'react';
import { toast } from 'react-toastify';
import TransactionForm from './TransactionForm';
import { FormProps } from './type/type';
import { useApi } from '../../hooks/useApi';
import { DEPOSIT_WITHDRAWAL_URL } from '../../utils/apiUrl';
import { FormikValues } from 'formik';

const WithdrawForm: React.FC<FormProps> = ({ userName, balance, onClose, userData }) => {
	const { sendRequest } = useApi();

	const handleFormSubmit = async (values: FormikValues) => {
		try {
			const response = await sendRequest(DEPOSIT_WITHDRAWAL_URL, 'POST', {
				user_id: userData.userId,
				type: 2, // 2 for withdrawal
				amount: Number(values.amount),
				remark: values.remark || null,
				master_password: values.masterPassword,
			});

			if (response.status === 200) {
				toast.success(response.message, {
					position: 'top-center',
					autoClose: 2000
				});
				onClose();
			} else {
				toast.error(response.message || 'Withdrawal failed. Please try again.', {
					position: 'top-center',
					autoClose: 2000
				});
			}
		} catch (error) {
			console.error('Withdrawal error:', error);
			toast.error('An error occurred. Please try again.');
		}
	};

	return (
		<TransactionForm
			userName={userName}
			balance={balance}
			onClose={onClose}
			onSubmit={(values) => handleFormSubmit(values)}
			formType='Withdrawal'
		/>
	);
};

export default WithdrawForm;