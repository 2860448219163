import { GET_PROFIT_LOSS_USERWISE_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { MRT_ColumnDef } from 'material-react-table';

const PLUserWise: React.FC = () => {
  const PLUserWiseColumns = [
    { accessorKey: 'sport_name', header: 'Sport' },
    { accessorKey: 'series_name', header: 'Series' },
    { accessorKey: 'match_name', header: 'Match' },
    { accessorKey: 'user_name', header: 'User' },
    { accessorKey: 'result', header: 'Result' },
    {
      accessorKey: 'stack',
      header: 'Stack',
    },
    {
      accessorKey: 'user_pl',
      header: 'User P/L',
    },
    {
      accessorKey: 'user_commission',
      header: 'Commission',
    },
    {
      accessorKey: 'created_at',
      header: 'Date',
      Cell: ({ row }: any) => formatDate(row.original.created_at),
    },
  ];

  const defaultPLUserWiseSettings = {
    start_date: '',
    end_date: '',
    statement_type: '0',
    type: '',
    user_name: '',
    user_id: '',
    page: 1,
    items: 50,
  };

  return (
    <CommonList
          title="Profit Loss User Wise"
          fetchUrl={GET_PROFIT_LOSS_USERWISE_URL}
          itemType="report-pl-user-wise"
          columns={PLUserWiseColumns as MRT_ColumnDef<Record<string, any>, unknown>[]}
          defaultSettings={defaultPLUserWiseSettings}
          searchRequestType="POST"
          addUrl=""
          updateSettingsUrl=""
        />
  );
};

export default PLUserWise;