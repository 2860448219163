import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

interface SettingsModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (formData: any) => void;
    initialValues: any;
    selectedIndex: number | null;
}

const allFields = [
    'name', 'marketId', 'matchId', 'seriesId', 'order_by', 'min_odds', 'max_odds',
    'bm_min_odds', 'bm_max_odds', 'match_bet_delay', 'bookmaker_bet_delay',
    'session_bet_delay', 'match_min_stack', 'match_max_stack', 'match_max_profit',
    'session_min_stack', 'session_max_stack', 'session_max_profit',
    'bookmaker_min_stack', 'bookmaker_max_stack', 'bookmaker_max_profit'
];

const validationSchema = Yup.object().shape(
    allFields.reduce((acc, field) => {
        acc[field] = Yup.string().required('Required');
        return acc;
    }, {} as Record<string, Yup.StringSchema>)
);

const SettingsModal: React.FC<SettingsModalProps> = ({ open, onClose, onSubmit, initialValues, selectedIndex }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Settings</DialogTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values, errors, touched, handleChange, isSubmitting }) => (
                    <Form>
    
                        <DialogContent>
                            <Grid container spacing={2}>
                                {allFields.map((field) => (
                                    <Grid item xs={12} sm={6} md={4} lg={4} key={field}>
                                        <TextField
                                            key={field}
                                            name={field}
                                            label={field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                                            fullWidth
                                            value={values?.[field]}
                                            onChange={handleChange}
                                            error={touched?.[field] && Boolean(errors?.[field])}
                                            margin="normal"
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={onClose}>Cancel</Button>
                            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                {selectedIndex !== null ? 'Update' : 'Submit'}
                            </Button> */}
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default SettingsModal;