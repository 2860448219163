import React from 'react'
import { 
    AccordionDetails,
    Typography,
  } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledAccordion,StyledAccordionSummary } from './styles';


const ScoreCard = ({expanded,handleAccordionChange}:{expanded:boolean,handleAccordionChange:any}) => {
  return (
    <StyledAccordion expanded={expanded} onChange={handleAccordionChange('panel1')}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon sx={{color:'white'}} />}>
          <Typography>SCORE CARD</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Typography>
            Score card details go here...
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
  )
}

export default ScoreCard