import { NavMenuList } from "../types/sideBarTypes";
export const navMenuList:NavMenuList = [
    {
      id: 1,
      name: "List Of Clients",
      path: "/",
    },
    { id: 2, name: "Market Analysis", path: "/" },
    // { id: 3, name: "Live Market", path: "/" },
    // { id: 4, name: "Live Virtual Market", path: "/" },
    {
      id: 5,
      name: "Reports",
      path: "#",
      subMenu: [
        { id: 51, name: "Betting Profit / Loss", path: "/reports/betting-profit-loss" },
        { id: 53, name: "Profit / Loss Sport wise", path: "/reports/profit-loss-sport-wise" },
        { id: 54, name: "Profit / Loss User wise", path: "/reports/profit-loss-user-wise" },
        { id: 55, name: "Cheat Bet User wise", path: "/reports/cheat-bet-user-wise" },
        { id: 56, name: "Bet History", path: "/reports/Bet History" },
        { id: 52, name: "Account Statement", path: "/reports/account-statement" },
        {
          id: 57,
          name: "Login History",
          path: "/reports/login-history",
        },
      ],
    },
    {
      id: 6,
      name: "Site Settings",
      path: "#",
      subMenu: [
        { id: 61, name: "Site Settings", path: "/settings/site-settings" },
        { id: 62, name: "Sports Settings", path: "/settings/sports-list" },
        { id: 63, name: "Series Settings", path: "/settings/series-list" },
        { id: 64, name: "Matches Settings", path: "/settings/matches-list" },
        { id: 65, name: "Market Settings", path: "/settings/market-list" },
        { id: 69, name: "Bank Manage", path: "/settings/bank-manage" },
        { id: 691, name: "Banner Manage", path: "/settings/banner-manage" },
      ],
    },
    {
      id: 7,
      name: "Results",
      path: "#",
      subMenu: [
        { id: 66, name: "Result Fancy", path: "/settings/result-fancy" },
        { id: 67, name: "Result Market", path: "/settings/result-market" },
        { id: 68, name: "Rollback Result", path: "/settings/rollback-result" },
      ],
    }
  ];
  