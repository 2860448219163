import { Typography } from '@mui/material';
import { LIST_MATCHES_URL, ADD_MATCH_URL, UPDATE_MATCH_SETTING_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';

const MatchListPage: React.FC = () => {
  const matchColumns = [
    { accessorKey: 'sport_name', header: 'Sport' },
    { accessorKey: 'series_name', header: 'Series' },
    { accessorKey: 'name', header: 'Match' },
    {
      accessorKey: 'created_at', header: 'Created At',
      Cell: ({ row }: any) => {
        // Convert date to a human-readable format    
        return (
          <Typography>{formatDate(row.original.created_at)}</Typography>
        );
      },
    },
    {
      accessorKey: 'updated_at', header: 'Updated At',
      Cell: ({ row }: any) => {
        return (
          <Typography>{formatDate(row.original.created_at)}</Typography>
        );
      },
    },
  ];

  const defaultMatchSettings = {
    // Define default match settings here
    // Add any specific match settings fields
  };

  return (
    <CommonList
      title="Match List"
      fetchUrl={LIST_MATCHES_URL}
      addUrl={ADD_MATCH_URL}
      updateSettingsUrl={UPDATE_MATCH_SETTING_URL}
      itemType="match"
      columns={matchColumns}
      defaultSettings={defaultMatchSettings} searchRequestType={'POST'}    />
  );
};

export default MatchListPage;