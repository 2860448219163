import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Grid, Typography, Box } from '@mui/material';

export const TransactionSchema = Yup.object().shape({
  amount: Yup.number()
    .min(100, 'Minimum amount is 100 ₹')
    .required('Amount is required'),
  masterPassword: Yup.string().required('Master Password is required'),
  remark: Yup.string(),
});

export interface TransactionFormProps {
  userName: string;
  balance: number;
  onClose: () => void;
  onSubmit: (values: any) => void;
  formType: 'Deposit' | 'Withdrawal' | 'DepositBonus' | 'WithdrawalBonus' | String;
}

const TransactionForm: React.FC<TransactionFormProps> = ({ userName, balance, onClose, onSubmit, formType }) => {
  const getFormTitle = () => {
    switch (formType) {
      case 'Deposit': return 'Deposit';
      case 'Withdrawal': return 'Withdrawal';
      case 'DepositBonus': return 'Deposit Bonus';
      case 'WithdrawalBonus': return 'Withdrawal Bonus';
    }
  };

  const getButtonColor = () => {
    switch (formType) {
      case 'Deposit':
      case 'DepositBonus':
        return '#4caf50'; // Green for deposits
      case 'Withdrawal':
      case 'WithdrawalBonus':
        return '#f44336'; // Red for withdrawals
    }
  };

  return (
    <Formik
      initialValues={{ amount: '', masterPassword: '', remark: '' }}
      validationSchema={TransactionSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Tech Admin: {userName}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">{balance}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{userName}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">0</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                {[100, 200, 500, 1000, 5000, 10000].map((amount) => (
                  <Button
                    key={amount}
                    variant="outlined"
                    size="small"
                    onClick={() => handleChange({ target: { name: 'amount', value: amount } })}
                  >
                    {amount} ₹
                  </Button>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="amount"
                label="Amount"
                fullWidth
                error={touched.amount && errors.amount}
                helperText={touched.amount && errors.amount}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="masterPassword"
                label="Master Password"
                type="password"
                fullWidth
                error={touched.masterPassword && errors.masterPassword}
                helperText={touched.masterPassword && errors.masterPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="remark"
                label="Remark"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Total: {values.amount || 0} ₹</Typography>
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={onClose} sx={{ mr: 1 }}>Cancel</Button>
            <Button 
              type="submit" 
              variant="contained" 
              style={{ backgroundColor: getButtonColor(), color: 'white' }}
            >
              {getFormTitle()}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TransactionForm;