import { Typography } from '@mui/material';
import { GET_PROFIT_LOSS_SPORT_WISE_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { MRT_ColumnDef } from 'material-react-table';

const PLSportWise: React.FC = () => {
    const PLSportWiseColumns = [
        { accessorKey: 'sport_name', header: 'Sport' },
        { accessorKey: 'series_name', header: 'Series' },
        { accessorKey: 'match_name', header: 'Match' },
        { accessorKey: 'user_name', header: 'User' },
        { accessorKey: 'result', header: 'Result' },
        {
          accessorKey: 'stack',
          header: 'Stack',
        },
        {
          accessorKey: 'user_pl',
          header: 'User P/L',
        },
        {
          accessorKey: 'user_commission',
          header: 'Commission',
        },
        {
          accessorKey: 'created_at',
          header: 'Date',
          Cell: ({ row }: any) => formatDate(row.original.created_at),
        },
      ];

  const defaultPLSportWiseSettings = {
    start_date: '',
    end_date: '',
    statement_type: '0',
    type: '',
    user_name: '',
    user_id: '',
    page: 1,
    items: 50,
  };

  return (
    <CommonList
          title="Profit Loss Sport Wise"
          fetchUrl={GET_PROFIT_LOSS_SPORT_WISE_URL}
          itemType="report-pl-sport-wise"
          columns={PLSportWiseColumns as MRT_ColumnDef<Record<string, any>, unknown>[]}
          defaultSettings={defaultPLSportWiseSettings}
          searchRequestType="POST"
          addUrl=""
          updateSettingsUrl=""
        />
  );
};

export default PLSportWise;