import { Typography } from '@mui/material';
import { GET_LOGIN_HISTORY_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { MRT_ColumnDef } from 'material-react-table';

const LoginHistory: React.FC = () => {
  const LoginHistoryColumns = [
    { accessorKey: 'user_name', header: 'User Name' },
    { accessorKey: 'device_type', header: 'Device Type' },
    { accessorKey: 'ip', header: 'IP Address' },
    { accessorKey: 'browser', header: 'Browser' },
    {
      accessorKey: 'created_at',
      header: 'Login Date',
      Cell: ({ row }: any) => formatDate(row.original.created_at),
    },
  ];

  const defaultLoginHistorySettings = {
    start_date: '',
    end_date: '',
    statement_type: '0',
    type: '',
    user_name: '',
    user_id: '',
    page: 1,
    items: 50,
  };

  return (
    <CommonList
          title="Login History"
          fetchUrl={GET_LOGIN_HISTORY_URL}
          itemType="report-login-history"
          columns={LoginHistoryColumns as MRT_ColumnDef<Record<string, any>, unknown>[]}
          defaultSettings={defaultLoginHistorySettings}
          searchRequestType="POST"
          addUrl=""
          updateSettingsUrl=""
        />
  );
};

export default LoginHistory;