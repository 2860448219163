import React from 'react'
import TransactionForm, { TransactionFormProps } from './TransactionForm'
import { FormProps } from './type/type';

const WithdrawlBonusForm:React.FC<FormProps>= ( { userName, balance, onClose }) => {
  
  const handleFormSubmit =()=>{

  }
    return (
   <TransactionForm 
   userName={userName}
   balance={balance}
   onClose={onClose}
   onSubmit={handleFormSubmit}
   formType='WithdrawalBonus'
  /> 


  )
}

export default WithdrawlBonusForm