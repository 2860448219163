import React, { useState } from "react";
import {
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledTabs,
  StyledTableContainer,
  StyledTab,
} from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MyBet = ({
  expanded,
  handleAccordionChange,
}: {
  expanded: boolean;
  handleAccordionChange: any;
}) => {
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const [tabValue, setTabValue] = useState(0);
  return (
    <StyledAccordion
      expanded={expanded}
      onChange={handleAccordionChange("panel2")}
    >
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon sx={{color:'white'}} />}>
        <Typography>MY BETS</Typography>
     
      </StyledAccordionSummary>
      <AccordionDetails>
        <StyledTabs value={tabValue} onChange={handleTabChange}>
          <StyledTab label="Matched Bets" />
          <StyledTab label="Unmatched Bets" />
        </StyledTabs>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>UserName</TableCell>
                <TableCell>Nation</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No records found
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default MyBet;
