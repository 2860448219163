import { Typography } from '@mui/material';
import { LIST_MARKETS_URL, ADD_MARKET_URL, UPDATE_MARKET_SETTING_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';

const MarketListPage: React.FC = () => {
  const marketColumns = [
    { accessorKey: 'sport_name', header: 'Sport' },
    { accessorKey: 'series_name', header: 'Series' },
    { accessorKey: 'match_name', header: 'Match' },
    { accessorKey: 'name', header: 'Market' },
    {
      accessorKey: 'created_at', header: 'Created At',
      Cell: ({ row }: any) => {
        // Convert date to a human-readable format    
        return (
          <Typography>{formatDate(row.original.created_at)}</Typography>
        );
      },
    },
    {
      accessorKey: 'updated_at', header: 'Updated At',
      Cell: ({ row }: any) => {
        return (
          <Typography>{formatDate(row.original.created_at)}</Typography>
        );
      },
    },
  ];

  const defaultMarketSettings = {
    min_odds: null,
    max_odds: null,
    min_stack: null,
    max_stack: null,
    bet_delay: null,
    max_profit: null,
    odds_mode: "0",
  };

  return (
    <CommonList
      title="Market List"
      fetchUrl={LIST_MARKETS_URL}
      addUrl={ADD_MARKET_URL}
      updateSettingsUrl={UPDATE_MARKET_SETTING_URL}
      itemType="market"
      columns={marketColumns}
      defaultSettings={defaultMarketSettings} searchRequestType={'POST'} />
  );
};

export default MarketListPage;