import React, { useState } from 'react';
import ScoreCard from './ScoreCard';
import MyBet from './MyBet';


const EventSideBar = () => {
  const [expanded, setExpanded] = useState<string | false>('panel1');


  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
        <ScoreCard expanded={expanded === 'panel1'} handleAccordionChange={handleAccordionChange}/>
      <MyBet expanded={expanded === 'panel2'} handleAccordionChange={handleAccordionChange}/>
    </div>
  );
};

export default EventSideBar;