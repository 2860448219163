import { 
    Accordion,
    AccordionSummary,
    Tabs, 
    Tab, 
    TableContainer, 
    Button,
    AccordionProps
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
interface StyledAccordionProps extends AccordionProps {
    expanded: boolean;
    onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  }

export const ViewMoreButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#923d3b",
  color: theme.palette.common.white,
  marginLeft: theme.spacing(2),
  "&:hover": {
    backgroundColor: "#7d3432",
  },
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

export const StyledTab = styled(Tab)({
  color: "white",
  "&.Mui-selected": {
    color: "white",
  },
});
export const StyledAccordion = styled(Accordion)<StyledAccordionProps>(({ theme }) => ({
  backgroundColor: 'white',
  color: 'black',
  marginBottom: theme.spacing(1),
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor:theme.palette.primary.main,
  color:'white'
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
}));
