import { ADD_MATCH_URL, UPDATE_MATCH_SETTING_URL, LIST_RESULTS, ROLLBACK_RESULT_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { useApi } from '../../hooks/useApi';
import { toast } from 'react-toastify';
import { Replay } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';

const RollbackResult: React.FC = () => {
  const { sendRequest } = useApi();

  const handleRollback = async (marketId: string, matchId: string) => {
    try {
      const response = await sendRequest(ROLLBACK_RESULT_URL, 'POST', {
        market_id: marketId,
        match_id: matchId,
        rollback_type: marketId?.includes("_") ? "1" : "2"
      });

      if (response.status === 200) {
        toast.success('Result rolled back successfully', {
          position: 'top-center',
          autoClose: 5000
        });
        // You might want to refresh the list here
      } else {
        toast.error(response.message || 'Failed to rollback result', {
          position: 'top-center',
          autoClose: 5000
        });
      }
    } catch (error) {
      toast.error('An error occurred while rolling back the result', {
        position: 'top-center',
        autoClose: 5000
      });
    }
  };

  const matchColumns = [
    { accessorKey: 'sport_name', header: 'Sport' },
    { accessorKey: 'series_name', header: 'Series' },
    { accessorKey: 'match_name', header: 'Match' },
    { accessorKey: 'name', header: 'Market Name' },
    {
      accessorKey: 'date',
      header: 'Date',
      Cell: ({ row }: any) => (
        <Typography>{formatDate(row.original.date)}</Typography>
      ),
    },
    { accessorKey: 'result', header: 'Result' },
    {
      accessorKey: 'action',
      header: 'Action',
      Cell: ({ row }: any) => (
        <Tooltip title="Rollback Result">
          <IconButton
            onClick={() => handleRollback(row.original.market_id, row.original.match_id)}
            color="primary"
          >
            <Replay />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const defaultMatchSettings = {
    // Define default match settings here
    // Add any specific match settings fields
  };

  return (
    <CommonList
      title="Rollback Result"
      fetchUrl={LIST_RESULTS}
      addUrl={ADD_MATCH_URL}
      updateSettingsUrl={UPDATE_MATCH_SETTING_URL}
      itemType='result-rollback'
      columns={matchColumns}
      defaultSettings={defaultMatchSettings}
      searchRequestType={'POST'}
    />
  );
};

export default RollbackResult;