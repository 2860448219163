import { Button, Switch } from '@mui/material';
import { ADD_SPORT_URL, LIST_SPORT_URL, UPDATE_SPORT_SETTING_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';

const SportList: React.FC = () => {

  const columns = [
    { accessorKey: 'name', header: 'Sport Name' },
    {
      accessorKey: 'sport_id',
      header: 'Sport Id',
      Cell: ({ row }: any) => (
        <Button variant="contained" color="primary" size="small">
          {row.original.sport_id}
        </Button>
      )
    },
  ];
  return (
    <CommonList
      title="Sport Setting"
      showFilter={false}
      fetchUrl={LIST_SPORT_URL}
      addUrl={ADD_SPORT_URL}
      updateSettingsUrl={UPDATE_SPORT_SETTING_URL}
      itemType='sport'
      columns={columns}
      defaultSettings={{}} 
      searchRequestType={'GET'}    />
  );
};

export default SportList;