import React,{useState} from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField, Button, Typography, Box } from "@mui/material";
import { FormProps } from "./type/type";
import { useApi } from "../../hooks/useApi";
import { CREDIT_LINE_URL } from "../../utils/apiUrl";
import {toast } from 'react-toastify';

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  oldCredit: Yup.number().required("Old Credit is required"),
  newCredit: Yup.number().required("New Credit is required"),
  masterPassword: Yup.string().required("Master Password is required"),
});

// Define the form values interface
interface FormValues {
  oldCredit: number;
  newCredit: number;
  masterPassword: string;
}

const CreditLimitForm: React.FC<FormProps> = ({
userData,
  userName,
  balance,
  onClose,
}) => {
  const { isLoading, error, sendRequest } = useApi();
  const initialValues: FormValues = {
    oldCredit: userData.creditReference,
    newCredit: 0,
    masterPassword: "",
  };

  const handleSubmit = async (values: FormValues) => {
   try{
    const result = await sendRequest(CREDIT_LINE_URL, "POST", {
      id:userData.userId,
      type: 1,
      amount: values.newCredit,
      master_password:values.masterPassword,
    });
    if(result.message){

      toast.success(result.message,{
        position:'top-center',
        autoClose:5000
      })
    }
  onClose()
   }catch(err:any){
    console.log(err)
    toast.error(err.message,{
      position:'top-center',
      delay:2000
    })

   }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Credit Limit
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Field
              as={TextField}
              name="oldCredit"
              label="Old Credit"
              type="number"
              fullWidth
              margin="normal"
              error={touched.oldCredit && !!errors.oldCredit}
              helperText={touched.oldCredit && errors.oldCredit}
            />
            <Field
              as={TextField}
              name="newCredit"
              label="New Credit"
              type="number"
              fullWidth
              margin="normal"
              error={touched.newCredit && !!errors.newCredit}
              helperText={touched.newCredit && errors.newCredit}
            />
            <Field
              as={TextField}
              name="masterPassword"
              label="Master Password"
              type="password"
              fullWidth
              margin="normal"
              error={touched.masterPassword && !!errors.masterPassword}
              helperText={touched.masterPassword && errors.masterPassword}
            />
         
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                bgcolor: "#b8b44e",
                "&:hover": { bgcolor: "#a09f45" },
              }}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CreditLimitForm;
