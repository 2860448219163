import { Typography } from '@mui/material';
import { ACCOUNT_STATEMENT_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { MRT_ColumnDef } from 'material-react-table';

const CheatBetUserWise: React.FC = () => {
  const CheatBetUserWiseColumns = [
    {
      accessorKey: 'date',
      header: 'Date',
      Cell: ({ cell }: { cell: { getValue: () => number } }) => cell.getValue().toFixed(2),
    },
    {
      accessorKey: 'credit',
      header: 'Credit',
      Cell: ({ cell }: { cell: { getValue: () => number } }) => cell.getValue().toFixed(2),
    },
    {
      accessorKey: 'debit',
      header: 'Debit',
      Cell: ({ cell }: { cell: { getValue: () => number } }) => cell.getValue().toFixed(2),
    },
    {
      accessorKey: 'closing',
      header: 'Closing',
      Cell: ({ cell }: { cell: { getValue: () => number } }) => cell.getValue().toFixed(2),
    },
    { accessorKey: 'description', header: 'Description' },
    { accessorKey: 'fromto', header: 'From/To' },
  ];

  const defaultCheatBetUserWiseSettings = {
    start_date: '',
    end_date: '',
    statement_type: '0',
    type: '',
    user_name: '',
    user_id: '',
    page: 1,
    items: 50,
  };

  return (
    <CommonList
          title="Cheat Bet User Wise"
          fetchUrl={ACCOUNT_STATEMENT_URL}
          itemType="match"
          columns={CheatBetUserWiseColumns as MRT_ColumnDef<Record<string, any>, unknown>[]}
          defaultSettings={defaultCheatBetUserWiseSettings}
          searchRequestType="POST"
          addUrl=""
          updateSettingsUrl=""
        />
  );
};

export default CheatBetUserWise;