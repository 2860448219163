import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  styled,
  useTheme,
  Paper,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material';
import { Edit, Delete, AddCircleOutline, Close } from '@mui/icons-material';
import { MRT_ColumnDef } from 'material-react-table';
import BaseTable from '../BaseTable';
import { StyledToolbar } from '../commonList/CommonList';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const MethodButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '15px',
  borderRadius: '10px',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

interface BankDetail {
  id: number;
  name: string;
  accountNumber: string;
  ifsc: string;
  qrCode: string;
}

const dummyData: BankDetail[] = [
  { id: 1, name: 'John Doe', accountNumber: '1234567890', ifsc: 'ABCD0001234', qrCode: 'QR1' },
  { id: 2, name: 'Jane Smith', accountNumber: '0987654321', ifsc: 'EFGH0005678', qrCode: 'QR2' },
];

interface WithdrawalMethod {
  name: string;
  image: string;
}

const withdrawalMethods: WithdrawalMethod[] = [
  { name: 'Paytm', image: 'https://miro.medium.com/v2/resize:fill:320:214/1*04xD0gseEr_g4wA7NLQ5iw.png' },
  { name: 'GPay', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq_S1I-kRo8EmqBe0MSfTSRdtAflozeeDoBQ&s' },
  { name: 'Bank Transfer', image: 'https://st.softgamings.com/uploads/BankTransfer_logo_1200x600.png' },
  { name: 'PhonePe', image: 'https://cdn.zeebiz.com/sites/default/files/styles/zeebiz_850x478/public/2023/02/14/227260-phonepe1.png?itok=MW54Zukx' },
  { name: 'UPI', image: 'https://m.economictimes.com/thumb/msid-74960608,width-1200,height-900,resizemode-4,imgsize-49172/upi-twitter.jpg' },
];

const BankDetails: React.FC = () => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [qrCode, setQrCode] = useState<File | null>(null);
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedMethod(null);
    resetForm();
  };

  const resetForm = () => {
    setAccountNumber('');
    setAccountName('');
    setIfscCode('');
    setQrCode(null);
  };

  const handleSubmit = () => {
    console.log({ selectedMethod, accountNumber, accountName, ifscCode, qrCode });
    handleCloseModal();
  };

  const columns: MRT_ColumnDef<BankDetail>[] = [
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'accountNumber', header: 'Account Number' },
    { accessorKey: 'ifsc', header: 'IFSC' },
    { accessorKey: 'qrCode', header: 'QR Code' },
    {
      accessorKey: 'actions',
      header: 'Action',
      Cell: ({ row }) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton color="primary">
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton color="error">
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const renderFormFields = () => {
    switch (selectedMethod) {
      case 'Paytm':
      case 'GPay':
      case 'PhonePe':
      case 'UPI':
        return (
          <>
            <TextField
              label="UPI ID"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              fullWidth
            />
            <TextField
              label="Account Name"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              fullWidth
            />
          </>
        );
      case 'Bank Transfer':
        return (
          <>
            <TextField
              label="Account Number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              fullWidth
            />
            <TextField
              label="Account Name"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              fullWidth
            />
            <TextField
              label="IFSC Code"
              value={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
              fullWidth
            />
          </>
        );
      case 'Payment Gateway':
        return (
          <TextField
            label="Payment Gateway ID"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            fullWidth
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <StyledToolbar>
        <Typography variant="h4">Bank Details</Typography>
      </StyledToolbar>

      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h4" gutterBottom >
          Withdrawal Methods
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {withdrawalMethods.map((method) => (
            <Grid item key={method.name}>
              <MethodButton
                variant="contained"
                onClick={() => {
                  setSelectedMethod(method.name);
                  handleOpenModal();
                }}
                sx={{
                  backgroundColor: selectedMethod === method.name ? 'primary.dark' : 'primary.main',
                }}
              >
                <img src={method.image} alt={method.name} style={{ width: '200px', height: '100px', marginBottom: '5px' }} />
                {method.name}
              </MethodButton>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5">
            Accounts List
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={() => {
              setSelectedMethod('Bank Transfer');
              handleOpenModal();
            }}
          >
            Add New
          </Button>
        </Box>
        <BaseTable columns={columns} data={dummyData} />
      </Paper>

      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            Add {selectedMethod || 'Bank'} Details
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            {renderFormFields()}
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="qr-code-upload"
              type="file"
              onChange={(e) => setQrCode(e.target.files?.[0] || null)}
            />
            <label htmlFor="qr-code-upload">
              <Button variant="outlined" component="span" fullWidth startIcon={<AddCircleOutline />}>
                Upload QR Code
              </Button>
            </label>
            {qrCode && <Box>File selected: {qrCode.name}</Box>}
            <Button variant="contained" onClick={handleSubmit} fullWidth>
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default BankDetails;