import React from 'react';
import { Formik, Form, Field } from 'formik';
import { 
  Box, 
  TextField, 
  Select, 
  MenuItem, 
  Button, 
  FormControl,
  InputLabel
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { FieldConfig } from './commonformConfig';

interface FilterFormProps {
  onSearch: (criteria: Record<string, any>) => void;
  fields: FieldConfig[];
  itemType: string;
}
export interface SearchCriteria {
  matchName?: string;
  status?: string;
  sport?: string;
  series?: string;
  matchId?:string;
  marketType?:string;
}
const FilterForm: React.FC<FilterFormProps> = ({ onSearch, fields, itemType }) => {
  const theme = useTheme();

  const initialValues = fields.reduce((acc, field) => {
    acc[field.key] = field.defaultValue || '';
    return acc;
  }, {} as Record<string, string>);
console.log(initialValues);
  const renderField = (field: FieldConfig) => {
    if (field.visibility === false) return null;

    const commonProps = {
      key: field.key,
      name: field.key,
      defaultValue: field.defaultValue || '',
      sx: { bgcolor: 'white', borderRadius: 1 }
    };

    switch (field.type) {
      case 'text':
        return (
          <Field
            as={TextField}
            type={field.type || 'text'}
            placeholder={field.placeholder}
            variant="outlined"
            size="small"
            label={field.label}
            {...commonProps}
          />
        );
      case 'select':
        return (
          <FormControl size="small" sx={{ minWidth: 120, ...commonProps.sx }}>
            <InputLabel>{field.label}</InputLabel>
            <Field
              as={Select}
              label={field.label}
              {...commonProps}
            >
              {field.options?.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Field>
          </FormControl>
        );
      case 'date':
        return (
          <>
            {field.label}
            <Field
              as={TextField}
              type='date'
              placeholder={field.placeholder}
              variant="outlined"
              size="small"
    
              {...commonProps}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSearch(values);
      }}
    >
      {({ submitForm }) => (
        <Form>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-start',
            alignItems: 'center', 
            flexWrap: 'wrap',
            gap: 2, 
            p: 2, 
            bgcolor: '', 
            borderRadius: 2 
          }}>
            {fields.map(field => renderField(field))}
            <Button 
              variant="contained" 
              onClick={submitForm}
              startIcon={<Search />}
              sx={{ bgcolor: theme.palette.primary.main, color: 'white', '&:hover': { bgcolor: '#a8ad33' } }}
            >
              Load
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default FilterForm;