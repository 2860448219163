import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';

const CarouselContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '300px',
  overflow: 'hidden',
}));

const CarouselSlide = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  transition: 'opacity 0.5s ease-in-out',
}));

const CarouselButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  color: theme.palette.common.white,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

interface CustomCarouselProps {
  children: React.ReactNode[];
}

export const CustomCarousel: React.FC<CustomCarouselProps> = ({ children }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % children.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [children.length]);

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + children.length) % children.length);
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % children.length);
  };

  return (
    <CarouselContainer>
      {React.Children.map(children, (child, index) => (
        <CarouselSlide sx={{ opacity: index === currentSlide ? 1 : 0 }}>
          {child}
        </CarouselSlide>
      ))}
      <CarouselButton onClick={goToPrevSlide} sx={{ left: 10 }}>
        <ArrowBackIosNewIcon />
      </CarouselButton>
      <CarouselButton onClick={goToNextSlide} sx={{ right: 10 }}>
        <ArrowForwardIosIcon />
      </CarouselButton>
    </CarouselContainer>
  );
};